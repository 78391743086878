export default function (axios) {
  return {
    index: (account,service) => {

      let params = { }

      if(account){
        params['account_id'] = account;
      }

      if(service) {
        params['service'] =  service;
      }
      return axios.get('platforms', { params: params }).then(response => response.data);
    },
    store: (platform) => {
      return axios.post('platforms', platform).then(response => response.data);
    },
    update: (id, form) => {
      return axios.put(`platforms/${id}`, form).then(response => response.data);

    },
    delete: (id) => {
      return axios.delete(`platforms/${id}`).then(response => response.data);
    },
    fetch: (platform_id) => {
      return axios.get(`platforms/${platform_id}`).then(response => response.data);
    },
    fetchServices: (params = {}) => {
      let url = `/platforms/${params.platform_name}/${params.entities}`
      if (params.platform_name) delete params.platform_name;
      return axios.get(url, { params: params })
        .then(response => response.data);
    },
    verifyToken: (platform_name, account, token,account_id,options=null) => {
      let url = `/platforms/verify-token`

      return axios.post(url, {
        'name': platform_name,
        'account': account,
        'account_id' : account_id,
        'token': token,
        'options' : options
      })
        .then(response => response.data);
    },
    addTag: (params = {}) => {
      let url = `/platforms/${params.platform_name}/${params.entities}`
      if (params.platform_name) delete params.platform_name;

      return axios.post(url, params)
        .then(response => response.data);
    },
    tags: (url) => {
      return axios.get(url)
        .then(response => response.data);
    },
    validate_expert_sender_customId: (eventId, email, platform_id) => {
      const url = `/platforms/expertsender/customId/validate?event_id=${eventId}&email=${email}&platform_id=${platform_id}`;
      return axios.get(url)
        .then(response => response.data);
    },

    createTag: (url, params = {}) => {
      return axios.post(url, params)
        .then(response => response.data);
    },
    match: (platform) => {
      return axios.post('platforms/match', platform).then(response => response.data);
    },
    automations: (platformId, platformName, listId = null) => {
      let url = `/platforms/${platformName.toLowerCase()}/automations?platform_id=${platformId}&list_id=${listId}`

      return axios.get(url).then(response => response.data);
    },
    names: (account,service = null) => {
      let params = { account_id: account }
      if(service) {
        params['service'] =  service;
      }
      return axios.get('platforms/names', { params: params }).then(response => response.data);
    }
  }
}
